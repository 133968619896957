<template>
<el-form ref="BidEvaluationTeamForm" :model="formdata" :rules="formRules" class="TeamForm" label-width="120px" :disabled="formDisabled">
      <el-form-item label="评标日期">
        <el-col :span="13">
          <el-form-item prop="bidEvaluationStartTime">
            <el-date-picker class="starttime" v-model="bidEvaluationStartTime" type="datetime" placeholder="选择日期"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="5" style="margin-left:20px">
        至
        </el-col>
        <el-col :span="13">
          <el-form-item prop="bidEvaluationEndTime">
            <el-date-picker v-model="bidEvaluationEndTime" type="datetime" placeholder="选择日期"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item label="评标专家" prop="bidExpertList">
        <div class="tableList">
          <div class="table">
            <el-table el-table :data="formdata.bidExpertList" border>
              <el-table-column prop="serialNumber" label="部门">
                <template #default="scope">
                    <el-select size="small" @change="setDeptName(scope)" filterable remote :remote-method="requestDeptOption (scope)" :loading="scope.row.departmentLoading" v-model="scope.row.deptCode" placeholder="请选择部门">
                      <el-option v-for="(item) in scope.row.departmentOptions"
                      :key="item.orgeh"
                      :label="item.oshor"
                      :value="item.orgeh">
                      </el-option>
                    </el-select>
                </template>
              </el-table-column>
              <el-table-column prop="bidScoringMes" label="参评人">
                <template #default="scope">
                  <el-select multiple size="small" filterable remote :remote-method="requestPsnOption (scope)" :loading="scope.row.participanLoading" value-key="username" v-model="scope.row.bidExpertMessageList" placeholder="请选择参评人">
                    <el-option v-for="(item) in scope.row.participantOptions"
                    :key="item.username"
                    :label="item.realName"
                    :value="item">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column prop="score" label="部门权重">
                <template #default="scope">
                  <div>
                    <div style="display:inline-block;width:90%">
                      <el-input oninput="value=value.replace(/[^\d]/g,'')" size="small" v-model="scope.row.deptWeight" placeholder="请输入内容"></el-input>
                    </div>
                    <span>%</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="!formDisabled" label="操作" width="120">
                <template #default="scope">
                  <template v-if="formdata.bidExpertList.length === 1">
                    <el-button size="mini" plain type="primary" @click="addBidTeam(scope.$index)" icon="el-icon-plus" circle></el-button>
                  </template>
                  <template v-else-if="formdata.bidExpertList.length === scope.$index + 1">
                    <el-button size="mini" plain type="primary" @click="removeBidTeam(scope.$index)" icon="el-icon-minus" circle></el-button>
                    <el-button size="mini" plain type="primary" @click="addBidTeam(scope.$index)" icon="el-icon-plus" circle></el-button>
                  </template>
                  <template v-else>
                    <el-button size="mini" plain type="primary" @click="removeBidTeam(scope.$index)" icon="el-icon-minus" circle></el-button>
                  </template>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-form-item>
 </el-form>
</template>

<script>
import dayjs from 'dayjs'
import { request } from '@/assets/js/http.js'
export default {
  name: 'BidEvaluationTeam',
  props: ['formData', 'dataFormat'],
  data () {
    return {
      formdata: this.formData,
      formDisabled: false,
      fieldShow: {},
      formRules: this.dataFormat ? this.dataFormat.rules : {},
      bidEvaluationStartTime: this.formData.bidEvaluationStartTime,
      bidEvaluationEndTime: this.formData.bidEvaluationEndTime
    }
  },
  mounted: function () {
    if (this.dataFormat) {
      if (this.dataFormat.formDisabled) {
        this.formDisabled = true
      }
      if (this.dataFormat.fieldShow) {
        this.fieldShow = this.dataFormat.fieldShow
      }
    }
    for (const item of this.formdata.bidExpertList) {
      this.requestDeptOption({ row: item })(item.deptCode)
      if (item.deptCode && item.deptCode !== '') {
        this.requestPsnOption({ row: item })(' ')
      }
    }
  },
  watch: {
    formData: function () {
      this.formdata = this.formData
      this.bidEvaluationStartTime = this.formData.bidEvaluationStartTime
      this.bidEvaluationEndTime = this.formData.bidEvaluationEndTime
    },
    bidEvaluationStartTime: function (val) {
      this.dateFormate(this.formdata, 'bidEvaluationStartTime', val)
    },
    bidEvaluationEndTime: function (val) {
      this.dateFormate(this.formdata, 'bidEvaluationEndTime', val)
    }
  },
  methods: {
    requestDeptOption (scope) {
      return (data) => {
        if (data === '') { return false }
        scope.row.departmentLoading = true
        request('/api/bid/getAllDept', 'get', { dept: data }).then((response) => {
          if (response.code === '200') {
            scope.row.departmentOptions = response.data
          }
          scope.row.departmentLoading = false
        })
      }
    },
    setDeptName (scope) {
      scope.row.departmentOptions.forEach(item => {
        if (scope.row.deptCode === item.orgeh) {
          scope.row.deptName = item.oshor
        }
      })
    },
    requestPsnOption (scope) {
      return (data) => {
        if (data === '') { return false }
        if (scope.row.deptCode === '') {
          this.$message({
            message: '请先选择部门！',
            type: 'error'
          })
          return false
        }
        if (data === ' ') {
          data = ''
        }
        scope.row.participanLoading = true
        request('/api/bid/getAllEmployees', 'get', { deptCode: scope.row.deptCode, username: data }).then((response) => {
          if (response.code === '200') {
            scope.row.participantOptions = response.data
          }
          scope.row.participanLoading = false
        })
      }
    },
    dateFormate: function (obj, field, data) {
      obj[field] = dayjs(data).format('YYYY-MM-DD HH:mm:ss')
    },
    // 添加一行参评信息
    addBidTeam (index) {
      this.formdata.bidExpertList.push({
        weight: '',
        departmentOptions: [],
        participantOptions: [],
        departmentLoading: false,
        participanLoading: false
      })
    },
    removeBidTeam (index) {
      this.formdata.bidExpertList.splice(index, 1)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
.TeamForm{
  .tableList{
    padding: 0 0;
  }
  .tableList :deep(.table){
    .el-table td {
      padding: 4px 0;
      .el-select{
        width: 100%;
      }
      .el-input__inner {
        border: none;
        background: none;
      }
      .el-button{
        padding: 3px 4px;
      }
    }
  }
  :deep(.is-disabled.starttime){
    width: 190px;
  }
}
</style>
